// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
	const storedJson = localStorage.getItem(name)
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}

var isDebugEnabled = process.env.REACT_APP_IS_DEBUG_ENABLED === "true";
var isLoggingEnabled = process.env.REACT_APP_IS_LOGGING_ENABLED === "true";
if(!isLoggingEnabled){
    console.log = ()=>{}
}

class createAccountStore {
    name = null;
    gameJoinCode = null;
    joinGameError = false;
    gameId = null;
    pronouns = "";
    region = null;
    fullName = ""
    money = 0;
    isDebugEnabled = isDebugEnabled;
    dcTimer = null;
    dcTimeOut = null;
    isFirstConnect= true
    log = []
    pendingJonTeamRequest = false;
    clear(){
        this.name = null;
        this.pronouns = "";
        this.region = null;
        this.fullName = ""
        this.gameJoinCode = null;
        this.joinGameError = false;
        this.gameId = null;
        this.money = 0;
        this.isFirstConnect = true;
        this.isDebugEnabled = isDebugEnabled;
        this.log = [];
        this.pendingJoinTeamRequest = false;
        localStorage.setItem('accountStore', null)
    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'accountStore')
    }
}

const accountStore = new createAccountStore();

//passthrough for cypress e2e testing
if (window.Cypress) {
    window.accountStore = accountStore;
}

export default accountStore;