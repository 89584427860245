import { statDisplayText } from "constants";
import accountStore from "stores/accountStore";
import kingdomStore from "stores/kingdomStore";
import matchStore from "stores/matchStore";
import regionStore from "stores/regionStore";
import schemeStore from "stores/schemeStore";
import navStore from "stores/navStore";
import styles from "./style.module.scss";
import wsStore from "stores/wsStore";
import { observer } from "mobx-react";
import rebellionStore from "stores/rebellionStore";

//TODO: figure out what's going on with this util function import
const getByIdFromArray = (arr, id) =>{
  for(let e of arr){
      if (e.id === id) {
          return e;
      }
  }
}

export default observer(function SchemeInfo() {
  let regionsObject = JSON.parse(JSON.stringify(regionStore.regions || null));
  let kingdomObject = JSON.parse(JSON.stringify(kingdomStore.kingdom || null));
  //if the user is not logged in, or if the websocket is not connected, don't show the scheme info
  //and redirect to /game page
  if (!accountStore.region || !wsStore.client) {
    navStore.navigate("/game");
    return <div></div>;
  }
  let myRegion = regionsObject[accountStore.region];
  let css = myRegion.current_scheme_step;  

  return (
    <div className={styles.container}>
      <div className={`${styles.header}`}>Scheme Information</div>
      <hr className={styles.separator}></hr>
      <div className={`${styles.name}  ${accountStore.region}-bg`}>
        {schemeStore.currentScheme}
      </div>
      {rebellionStore.isRebelling && <h2 class="rebelion-notice">Your Region is currently in Rebellion, and your Scheme has been paused.</h2>}
      {css == null &&
            <div>
                <div className={styles.objective}>
                    Ensure the monarch's ambition succeeds while thwarting the other regions' schemes.
                </div>
            </div>
      }

      {css != null && [css].map((e, i) => {
        var targetStat;
        if (e.grouping === "Kingdom") {
          targetStat = getByIdFromArray(kingdomObject.stats || [], e.stat.id);
        } else {          
          targetStat = getByIdFromArray(
            regionsObject[e.grouping] || [],
            e.stat.id
          );
        }
        return (
          <div key={i}>
            <div className={styles.objective}>
              {schemeStore.objective}
            </div>
            {targetStat && (
              <div className={styles.current}>
                Current {e.grouping} {statDisplayText[targetStat.id]}:{" "}
                {targetStat.value}
              </div>
            )}
          </div>
        );
      })}
      <div className={styles.button} data-button="back" onClick={() => navStore.navigate("/game")}>
        BACK
      </div>
    </div>
  );
})
