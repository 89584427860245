export default {
    choices: [
      {
        text: "Modernization.",
        locked: false,
        stat_changes: [],
        chosen: true,
        scheme_set: {
          scheme_id: "some_scheme",
          scheme_goals:
            "Raise your own Military, Raise other regions' total Trade",
        },
      },
      { text: "Propaganda.", locked: false, stat_changes: [], chosen: false },
      { text: "Gunpowder.", locked: false, stat_changes: [], chosen: false },
    ],
    variants: [
      { variant_type: "MONARCH'S CHOICE", target: -1 },
      { variant_type: "VETO", target: -1 },
    ],
    region_id: "chiefs",
    is_voting_open: true,
    message_type: "VOTE_STEP",
  };
  