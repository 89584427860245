import { makeAutoObservable } from "mobx";

class createBuildingStore {
    buildings = [];
    regions = [];
    mappedBuildings = {}
    buildingCount = 0
    clear = () => {
        this.regions = []
        this.buildings = []
        this.mappedBuildings = {}
        this.buildingCount = 0
    }
    constructor() {
        makeAutoObservable(this)
    }
}

const buildingStore = new createBuildingStore();
export default buildingStore;