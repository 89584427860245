import wsStore from "stores/wsStore"
import accountStore from "stores/accountStore"
import authStore from "stores/authStore"
import matchStore from "stores/matchStore"
import rebellionStore from "stores/rebellionStore";
//Outgoing requests ---------------------------------------

export function sendVoteRequest(choice)
{
    if(['A', 'B', 'C', 'D', 'E'].indexOf(choice) === -1){
        throw(`Invalid vote option ${choice}`);
    }

    let voteChoice = choice.toLowerCase();
    publish(`!vote ${voteChoice}`);
}


export function sendBuildingVote(choice){
    if(['A', 'B', 'C', 'D', 'E', 'F'].indexOf(choice) === -1){
        throw(`Invalid vote option ${choice}`);
        return;
    }

    publish(`!fund ${choice.toUpperCase()} 100`);
}

export function sendRebelVote(){
    rebellionStore.hasVotedToRebel = true;
    publish("!rebel");
}

export function sendJoinRequest(pronouns){
    publish(`!join ${pronouns}`);
}

export function sendJoinTeamRequest(pronouns, team){
    publish(`!join ${pronouns} ${team}`);
}

export function sendLeaveRequest(pronouns){
    publish(`!leave`);
}

export function requestSync(){
    matchStore.forcedSyncCount += 1;
    publish("!sync");
}

function publish(body){
    if(!wsStore.client || !wsStore.client.connected){
        console.log(`Attempting to publish message, but client closed. Message was: ${body}`);
        return;
    }
    
    var endpoint = `/app/game/event/${accountStore.gameId}`;
    console.log(`[WS] Publishing: '${body}' to ${endpoint}`);
    wsStore.client.publish({
        destination: endpoint,
        body: body,
        headers: {
            authToken: authStore.gameToken
        }
    });
}
