export default {
  "nobles": [
    {
      "name": "laurie",
      "full_name": "Conte laurie",
      "region_id": "counts",
      "wealth": 1000,
      "shield": {
        "noble_id": "0-debug",
        "shield_definition_id": "shield01",
        "colors": [
          "B9172FFF",
          "000005FF"
        ],
        "icon_id": "ShieldIcon12"
      }
    },
    {
      "name": "CLAAAY",
      "full_name": "Chief CLAAAAAAAAAAAAAAAAAAAAAAY",
      "region_id": "chiefs",
      "wealth": 1000,
      "shield": {
        "noble_id": "2-debug",
        "shield_definition_id": "shield13",
        "colors": [
          "653E97FF",
          "382171FF",
          "000000FF"
        ],
        "icon_id": "ShieldIcon14"
      }
    }
  ],
  "message_type": "NOBLE_STATE"
}