
import accountStore from "stores/accountStore";
import voteStore from "stores/voteStore";
import navStore from "stores/navStore";
import buildingStore from "stores/buildingStore";
import rebellionStore from "stores/rebellionStore";
import schemeStore from "stores/schemeStore"
import regionStore from "stores/regionStore"
import matchStore from "stores/matchStore";
import kingdomStore from "stores/kingdomStore";

export default  () => { 
    accountStore.clear();
    rebellionStore.clear();
    buildingStore.clear();
    schemeStore.clear();
    regionStore.clear();
    voteStore.clear();
    navStore.clear();
    localStorage.clear();
}

// Laurie - 23/08/22
// The default function here does not clear *all* data.
// It seems to clear some subset of data which is useful when ?handling? ?game events? 
// /This/ function also clears the matchStore and kingdomStore, which is required when *Quiting* the game.
// This was introduced when cleaning up gameJoinCode storage.
// It worked previously because the gameCode was duplicated between matchStore and accountStore,
// And there was insane custom logic to find if one was populated but not the other.

// 09/12/22 
// This does not clear the (new) authStore. Because we persist auth tokens forever. It is very unlikely that they should need to be cleared.
// However, its name is now even more stupid.
// Look into removing the default export function.
export function clearAllData() {
    matchStore.clear();
    kingdomStore.clear();
    accountStore.clear();
    rebellionStore.clear();
    buildingStore.clear();
    schemeStore.clear();
    regionStore.clear();
    voteStore.clear();
    navStore.clear();
    localStorage.clear();   
}
