import { observer } from "mobx-react";
import { useEffect } from "react";
import kingdomStore from "stores/kingdomStore";
import navStore from "stores/navStore";
import styles from "../NobleInfo/style.module.css";

export default observer(function Pause() {
  useEffect(() => {
    if (!kingdomStore.kingdom.is_paused) {
      navStore.pop();
    }
  }, [kingdomStore?.kingdom?.is_paused]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} data-textbox="main-title">
        Game paused
      </div>
      <div className={styles.waiting} data-textbox="main-subtitle">
        <i>Waiting for host to continue.</i>
      </div>
    </div>
  );
});