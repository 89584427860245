export default {
    "rebellions": [
        {
            "has_ended": false,
            "has_failed": true,
            "is_gathering": false,
            "is_main": false,
            "is_ongoing": false,
            "leader": null,
            "region_id": "barons",
            "rebel_ids": []
        }, {
            "has_ended": false,
            "has_failed": false,
            "is_gathering": true,
            "is_main": false,
            "is_ongoing": false,
            "leader": null,
            "region_id": "barons",
            "rebel_ids": []
        }
    ],
    "message_type": "REBELLION_STATE"
}
