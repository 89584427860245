export default {
  kingdom: {
    id: "Kingdom",
    stats: [
      { id: "authority", value: 5 },
      { id: "stability", value: 6 },
      { id: "treasury", value: 3200 },
      { id: "rebellionVictory", value: 0 },
      { id: "rebellionDefeat", value: 0 },
    ],
  },
  message_type: "KINGDOM_STATE",
};