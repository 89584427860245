import authStore from "stores/authStore";
import jwt_decode from "jwt-decode";
const REACT_APP_API_SCHEME = process.env.REACT_APP_API_SCHEME;
const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;
const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export function anonymousLogin()
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
     return fetch(`${REACT_APP_API_SCHEME}://${REACT_APP_API_HOST}:${REACT_APP_API_PORT}/account/anonymous-login`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not OK');
              }
            return response.json()})
        .then(function(data){
            authStore.loginToken = data.session_token;
            var decoded = jwt_decode(data.session_token);
            authStore.decodedLoginToken = decoded;
        });
}
