/* eslint-disable jsx-a11y/alt-text */
import styles from "./style.module.scss";

import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import matchStore from "stores/matchStore";
import accountStore from "stores/accountStore";
import schemeStore from "stores/schemeStore";

import Logo from "components/logo";

import Container from "components/container";
import { getAsset } from "util/getAsset";
import { regionLabelsText } from "constants";
import rebellionStore from "stores/rebellionStore";
import navStore from "stores/navStore";

export default observer(function NobleInfoHeader(props) {
  const lilbanner = getAsset(
    `flag_${accountStore.region}.png`,
    "flag_counts.png"
  );
  const shield = getAsset(
    `banner_${accountStore.region}.png`,
    "banner_counts.png"
  );
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.headerThing}>
        <Logo className={styles.logo} />
        <div className={styles.sideWrap}>
          <div className={styles.moneys}>
            <img
              src={getAsset("stat_treasury.png")}
              className={styles.monies}
              alt="icon for money"
            />
            {accountStore.money}
          </div>
          <div className={styles.regionLabel} data-region={accountStore.region}>
            {regionLabelsText[accountStore.region]}
            <img src={shield} className={styles.sheild} alt="region shield" />
          </div>
          <div className={styles.lilbanner}>
            <img src={lilbanner} className={styles.lilbannerImage} alt="" />
            <div className={styles.nameLabel}>{accountStore.name}</div>
          </div>
        </div>
      </div>
      <div className={styles.currentScheme}>
        {schemeStore.currentScheme && (
          <span onClick={() => {
            navStore.navigate("/game/scheme-info")
          }}>
            {" "}
            CURRENT SCHEME:{" "}
            <span className={`${styles.scheme} ${accountStore.region}-color `} data-button="scheme">
              {schemeStore.currentScheme.toUpperCase()}
            </span>{" "}
            <span className={styles.questionMark}>?</span>{" "}
          </span>
        )}

        {rebellionStore.rebellions.some(x => ((x.is_gathering || x.is_ongoing) && (!x.initial_turn_id || (matchStore.turn - 1 > x.initial_turn_id)))) && (
          <span
            className={styles.rebellion}
            data-button="rebellion"
            onClick={() => {
              navStore.navigate("/game/rebellion")
            }}
          >
            REBELLIONS
          </span>
        )}
      </div>
    </Container>
  );
});
