// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";

class createWSStore {
    client = null;
    gameMsgSubscription = null;
    constructor() {
        makeAutoObservable(this)
    }
}

const wsStore = new createWSStore();
//hard override for cypress e2e testing
if (window.Cypress) {
    wsStore.client = {publish(data){
        console.log(`ws request for ${data.body} has been intercepted`)
    }, 
deactivate(){
    console.log("ws connection has been deactivated")
}}
    //passthrough for cypress e2e testing sanity check
    window.wsClient = wsStore.client;
}
export default wsStore;