export default {
  buildings: [
    {
      region_id: "barons",
      build_state: "FUNDING",
      definition: { id: "library", reward: { value: 1, stat_id: "stability" } },
    }
  ],
  message_type: "BUILDING_STATE",
};
