import rebellionStore from "stores/rebellionStore";
import { getAsset } from "util/getAsset";
import styles from "./style.module.scss";
import { sendRebelVote } from "actions/voting/sendVote";
import { regionLabelsText } from "constants";
import accountStore from "stores/accountStore";
import regionStore from "stores/regionStore";
import { observer } from "mobx-react";
import navStore from "stores/navStore"
import kingdomStore from "stores/kingdomStore";
import matchStore from "stores/matchStore";

export default observer(function RebellionInput() {
  return (
    <div>
      <div className={styles.header}>The Regions are Rebelling!</div>
      <div className={styles.information}>
        Whichever side has the most victory points will win the Rebellion. If
        the Rebels win, the Monarch is overthrown
      </div>

      {rebellionStore.rebellions?.map((rebellion, i) => {
        var defiance = regionStore.regions[rebellion.region_id]?.stats?.find(x => x.id === "defiance").value ?? 0;
        var authority = kingdomStore.kingdom.stats?.find(x => x.id == "authority").value ?? 10;
        var stability = kingdomStore.kingdom.stats?.find(x => x.id == "stability").value ?? 10;

        var status = "";
        var showButton = false;
        var reason = undefined;
        if (rebellion.is_ongoing) status = "Currently rebelling!";
        else if (rebellion.is_gathering) {
          status = "On the verge of Rebellion!";
          if (rebellion.region_id === accountStore.region && (!rebellion.initial_turn_id || (matchStore.turn - 1 > rebellion.initial_turn_id))) {
            showButton = true;
          }
        }
        else {
          status = "Cannot start a Rebellion.";
          var threshold = Math.max(authority, stability);
          reason = defiance > threshold
            ? `Will be able to rebel next Season if Defiance (${defiance}) remains greater than ${threshold}`
            : `Defiance is too low (${defiance}) - needs to be greater than ${threshold}`;
        }

        //hide rebel button if already voted
        if(rebellionStore.hasVotedToRebel){
          showButton = false;
        }

        return (
          <div key={i} className={`${styles.block} ${rebellion.region_id}-bg ${showButton && styles.rblHasJoin} showButton-${showButton}`}>
            <img
              className={styles.emblem}
              src={getAsset(`banner_${rebellion.region_id}.png`)}
            />
            <div className={`${styles.title} ${rebellion.region_id}-accent-bg`}>
              {regionLabelsText[rebellion.region_id]}
            </div>
            <div className={styles.status}>{status}</div>
            {reason && (
              <div className={styles.reason}>{reason}</div>
            )}
            {showButton && (
              <div className={styles.button} onClick={sendRebelVote}>
                <div>JOIN REBELLION</div>
                <img
                  className={styles.joinBtnBg}
                  src={getAsset("button_join_rebellion.png")}
                  alt=""
                />
              </div>
            )}
          </div>
        );
      })}
      {/* <pre>
        rebellion view: {matchStore.rebellionView.toString()} <br />
        game mode: {voteStore.mode}
      </pre> */}
      <div className={styles.backButton} data-button="back" onClick={()=>  navStore.navigate("/game")}>BACK</div> 
    </div>
  );
})
