import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";

import TopNav from "components/topnav";
import Background from "components/background";
import Container from "components/container";
import styles from "./style.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import FlagButton from "components/flagButton";
import accountStore from "stores/accountStore";
import authStore from "stores/authStore";
import SteamIcon from "components/steamIcon";
import votesCastIcon from "./icon_votesCast.png";
import clearData from "actions/clearData";
const fourHours = 14400000;

export default observer(function Login(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  var jwt = searchParams.get("jwt");
  useEffect(() => {
    if (jwt && jwt.length > 0) {
      var decoded = jwt_decode(jwt);
      authStore.loginToken = jwt;
      authStore.decodedLoginToken = decoded;
      accountStore.name = decoded.display_name;
      navigate("/login");
      return <div />;
    }
    if (accountStore.gameJoinCode && new Date().getTime() - accountStore.dcTimer < fourHours) {
      console.log(`Landed on index page but found existing game code '${accountStore.gameJoinCode}'. Redirecting to game page.`);
      navigate("/game");
      return <div />;
    }

    // If we don't have an active game session (handled above), then we always clear Steam-authed tokens.
    // This serves two purposes:
    // - Steam-authed tokens only last 12h, so this ensures we are connecting with one that hasn't expired;
    // - If the (previously steam-authed) user wants to login as a guest, we must clear their existing token.
      
    // We don't clear guest-authed tokens because they last forever. 
    // This ensures that the user always connects with the same user_id, so keeps customisations, region, etc...
    // Not expiring them isn't a user-security risk, because if one gets stolen, it's not tied to any real account.
    if(authStore.isSteamLogin()){
        authStore.clear();
    }
    clearData();
  });

  return (
    <>
      <TopNav />
      <Background />
      <Container className={styles.container}>
        <FlagButton
          className={styles.flagButton}
          onClick={() => {
            authStore.clear();
            window.location.href = `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/account/steam-openid-login/`;
          }}
        >
          <SteamIcon className={styles.steamIcon} /> JOIN WITH STEAM
        </FlagButton>
        <FlagButton
          className={styles.flagButton}
          onClick={() => {
            navigate("/login");
          }}
        >
          <img
              src={votesCastIcon}
              className={styles.chooseVoteIcon}
              alt="a quil"
          />
          JOIN AS GUEST
        </FlagButton>
      </Container>
    </>
  );
});
