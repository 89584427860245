export default {
   "choices":[
      {
         "text":"Spare no expense in rebuilding what was lost.",
         "locked":false,
         "stat_changes":[
            {
               "stat_id":"treasury",
               "net_change":-1240,
               "change":"Decrease"
            },
            {
               "stat_id":"defiance",
               "net_change":-2,
               "change":"Decrease"
            },
            {
               "stat_id":"stability",
               "net_change":1,
               "change":"Increase"
            },
            {
               "stat_id":"trade",
               "net_change":1,
               "change":"Increase"
            },
            {
               "stat_id":"farming",
               "net_change":1,
               "change":"Increase"
            }
         ],
         "chosen":false,
         "scheme_set":null
      },
      {
         "text":"Send extra troops from the March to clear the roads.",
         "locked":false,
         "stat_changes":[
            {
               "stat_id":"defiance",
               "net_change":0,
               "change":"Both"
            },
            {
               "stat_id":"military",
               "net_change":-1,
               "change":"Decrease"
            },
            {
               "stat_id":"trade",
               "net_change":1,
               "change":"Increase"
            },
            {
               "stat_id":"farming",
               "net_change":1,
               "change":"Increase"
            }
         ],
         "chosen":false,
         "scheme_set":null
      },
      {
         "text":"The East will have to fend for itself.",
         "locked":false,
         "stat_changes":[
            {
               "stat_id":"defiance",
               "net_change":2,
               "change":"Increase"
            },
            {
               "stat_id":"stability",
               "net_change":-1,
               "change":"Decrease"
            }
         ],
         "chosen":false,
         "scheme_set":null
      },
      {
         "text":"Ask the Church to lead the relief efforts.",
         "locked":false,
         "stat_changes":[
            
         ],
         "chosen":false,
         "scheme_set":null
      }
   ],
   "variants":[
      {
         "variant_type":"MONARCH'S CHOICE",
         "target":-1
      },
      {
         "variant_type":"VETO",
         "target":-1
      }
   ],
   "region_id":null,
   "is_voting_open":true,
   "message_type":"VOTE_STEP"
}