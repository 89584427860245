import matchStore from "stores/matchStore";
import accountStore from "stores/accountStore";
import rebellionStore from "stores/rebellionStore";
import schemeStore from "stores/schemeStore";
import regionStore from "stores/regionStore";
import kingdomStore from "stores/kingdomStore";
import voteStore from "stores/voteStore";
import navStore from "stores/navStore";
import buildingStore from "stores/buildingStore";
import messageHandlerStore from "stores/messageHandlerStore";

import wsStore from "stores/wsStore";
import { observer } from "mobx-react";
import { useState } from "react";
import style from "./style.module.scss";

const disconnectFunc = () => {
  console.log(wsStore.client);
  wsStore.client.deactivate();
};

const downloadLog = () => {
  const gameState = {};
  gameState.accountStore = accountStore;
  gameState.matchStore = matchStore;
  gameState.rebellionStore = rebellionStore;
  gameState.schemeStore = schemeStore;
  gameState.regionStore = regionStore;
  gameState.navStore = navStore;
  gameState.buildingStore = buildingStore;
  gameState.kingdomStore = kingdomStore;
  gameState.voteStore = voteStore;

  var text = "export default " + JSON.stringify(gameState, null, 2);
  console.log(text);
  const file = new File(
    [text],
    `KOTC_WEB_LOG-${accountStore.gameJoinCode}-${new Date().toDateString()}.txt`,
    {
      type: "text/plain",
    }
  );
  const link = document.createElement("a");
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default observer(function DebugStuff() {
  const [hidden, setHidden] = useState(false);

  var dataList = [
    "NOBLE_STATE.js|(sample comment)",
    `FULL_STATE.js|Count: ${matchStore.forcedSyncCount}`,
    "BUILDING_STATE.js|",
    "BUILDING_STATE-one.js|",
    "BUILDING_STATE-end.js|",
    "BUILDING_STATE-end-FUNDED.js|",
    "BUILDING_STATE-funded-buildings",
    "KINGDOM_STATE.js|",
    "REGION_STATE.js|",
    "KINGDOM_STATE-victory",
    "POLL_STEP.js|",
    "VOTE_STEP-not-open.js",
    "VOTE_STEP-end.js|",
    "VOTE_STEP-both.js|",
    "VOTE_STEP-veto.js|",
    "VOTE_STEP.js|",
    "REBELLION_STATE-has-duplicate.js|",
    "REBELLION_STATE-small.js|",
    "REBELLION_STATE-cheifs.js",
    "REBELLION_STATE.js",
    "REGION_STATE-victory.js",
    "REGION_STATE-with-scheme.js",
    "VOTE_STEP-monarchs-choice.js",
    "VOTE_STEP-successor.js",
    "VOTE_STEP-locked-rebelion.js",
    "scheme.js",
    "scheme-end.js",
    "scheme-italics-vote.js",
    "TURN_STATE-1.js",
    "TURN_STATE-2.js",
  ];

  return (
    <div className={style.debugMenu}>
      <button
        onClick={() => {
          setHidden(!hidden);
        }}
      >
        Show/Hide debug
      </button>
      <button
        onClick={() => {
          downloadLog();
        }}
      >
        Download Log
      </button>
      {hidden &&
        [].concat(
          dataList.map((e, i) => {
            let filename = e.split("|")[0];
            let comment = e.split("|")[1];
            return (
              <button
                disabled={!accountStore.name}
                key={i}
                onClick={() => {
                  let data = require("./MockData/" + filename).default;
                  messageHandlerStore.events.emit(data.message_type, data);
                }}
              >
                {filename.split(".")[0]} {comment}
              </button>
            );
          })
        )}
    </div>
  );
});
