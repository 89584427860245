import React, { useEffect } from "react";
import TopNav from "components/topnav";
import Background from "components/background";
import Container from "components/container";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import Input from "components/basicInput";
import matchStore from "stores/matchStore";
import { joinGame } from "actions/join";
import BannerHeader from "components/bannerHeader";
import FlagButton from "components/flagButton";
import { useState } from "react";
import { observer } from "mobx-react";
import accountStore from "stores/accountStore";
import LoadingPage from "pages/LoadingPage";

export default observer(function Join(props) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [joining, setJoining] = useState(false);

  // bit of tribal knowledge here, but this is reconnect logic
  // the region is null until the app has received the region from the server
  // the client could also have a region stored in local storage from a previous game
  // in either case, once it has a value we need to send the user to the game
  // since this page will be stuck displaying "loading" until this bit of logic fires off 
  useEffect(() => {
  if (accountStore.region) {
    navigate("/game");
    console.log("Join page found a region, redirecting to game");
  }
}, [accountStore.region]);

  return joining ? (
    <LoadingPage />
  ) : (
    <>
      <TopNav />
      <Background />
      <Container className={styles.container}>
        <BannerHeader className={styles.banner}>ENTER GAME CODE</BannerHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setErrors("");
            setJoining(true);
            joinGame()
              .then((data) => {
                if (data.error) {
                  setJoining(false);
                  if (data.error === "Unauthorized") {
                    navigate("/");
                    return;
                  }
                  if (data.message === "MONARCH_NOT_JOINED") {
                    setErrors("The Monarch is disconnected.");
                    return;
                  }
                  if (data.message === "GAME_CODE_NOT_VALID") {
                    setErrors("Invalid room code.");
                    return;
                  }
                  if (data.message === "GAME_CODE_NOT_FOUND") {
                    setErrors("Room not found!");
                    return;
                  }
                  if (data.message === "NOBLE_IS_MONARCH") {
                    setErrors("Monarch cannot join their own game.");
                    return;
                  }

                  if (data.message === "PLAYER_LIMIT_REACHED") {
                    setErrors(
                      "The maximum number of players for web mode has been reached."
                    );
                    matchStore.errorMsg =
                      "The maximum number of players has been reached.";
                    matchStore.errorTitle = "Game Full!";
                    matchStore.error = true;
                    return;
                  }
                  setErrors(data.message);
                } else {
                  accountStore.dcTimer = new Date().getTime();
                  clearTimeout(accountStore.dcTimeOut);
                }
              })
              .catch((error) => {
                setErrors("There was a problem with logging in");
              });
          }}
        >
          <p className={styles.infoMessage}>
            Enter the four letter room code: e.g EXLZ.
          </p>
          <Input
              className={styles.gameCodeInput}
            placeholder="enter code..."
            maxLength={4}
            setValue={(x) => {
              accountStore.gameJoinCode = x.toUpperCase();
            }}
            onInput={(e) => {
              e.target.value = ("" + e.target.value).toUpperCase();
            }}
          />
          <h4 className="errors">{errors}</h4>
          <br />
          <FlagButton type="submit" disabled={accountStore.gameJoinCode?.length < 4}>
            Join
          </FlagButton>
        </form>
      </Container>
    </>
  );
});
