export default {
    "poll": {
        "1b867c3b-040d-4a7d-92f8-184eb759969f": {
            "author_name": "test",
            "id": "1b867c3b-040d-4a7d-92f8-184eb759969f",
            "value": 0
        }
    },
    "message_type": "POLL_STATE"
}
