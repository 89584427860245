import styles from "./style.module.scss";
import { observer } from "mobx-react";
import accountStore from "stores/accountStore";
import buildingStore from "stores/buildingStore";
import { sendBuildingVote } from "actions/voting/sendVote";
import { getAsset } from "util/getAsset";
import { regionLabelsText, letters } from "constants";
import voteStore from "stores/voteStore";
import navStore from "stores/navStore"

export default observer(function BuildingsInput() {
  return (
    <div>
      <div className={styles.header}>Building Voting is Open!</div>
      <div className={styles.wealth}>Personal wealth: {accountStore.money}</div>

      {buildingStore.regions.map((region, i) => {
        if(buildingStore.mappedBuildings[region]?.length === 0){
          return;
        }
        return (
          <div key={i} className={`${styles.block} ${region}-bg`}>
            {/* <pre>{JSON.stringify(x, null, 2)}</pre> */}
            <div className={styles.emblem}>
              <img src={getAsset(`banner_${region}.png`)} alt="region banner" />
            </div>
            <div className={`${styles.title} ${region}-accent-bg`}>
              {regionLabelsText[region]}
            </div>
            {buildingStore.mappedBuildings[region] &&
              buildingStore.mappedBuildings[region].map((item, i) => {
                let buildingName = item?.definition?.id;
                return (
                  <div
                    key={i}
                    className={`${styles.subblock}`}
                    onClick={() => {
                      sendBuildingVote(letters[item.index]);
                    }}
                  >
                    <div className={styles.icon}>
                      <img
                        src={getAsset(`icon_${buildingName}.png`)}
                        alt="region icon"
                      />
                    </div>
                    <div>
                      <div className={`${styles.name} ${region}-color`}>
                        <img
                          className={styles.bldgNameBg}
                          src={getAsset("bldgNameBg.png")}
                          alt="bnbg"
                        />
                        {buildingName.toUpperCase()}
                      </div>
                      <div className={styles.stat}>
                        {item?.definition?.reward.value > 0 ? "Raises" : "Lowers"}{" "}
                        {item?.definition?.reward.stat_id}{" "}
                        {Math.abs(item?.definition?.reward.value)}.
                      </div>
                    </div>
                    <div>
                      <div className={styles.pledgeText}>PLEDGE</div>
                      <div
                        className={`${styles.pledgeAmount} ${
                          accountStore.money < 100 && styles.disabledPledge
                        }`}
                      >
                        <img
                          className={styles.pledgeBackgroundImg}
                          src={getAsset("pledgeBg.png")}
                          alt="pbg"
                        />
                        <img
                          className={styles.moneyIcon}
                          src={getAsset("stat_treasury.png")}
                          alt="mi"
                        />
                        <div>100</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
});
