export const letters = ["A", "B", "C", "D", "E", "F"];

export const regionLabelsText = {
    counts: "COUNTS OF THE EAST",
    barons: "BARONS OF THE MARCH",
    patricians: "PATRICIANS OF THE COAST",
    grandees: "GRANDEES OF THE SOUTH",
    chiefs: "CHIEFS OF THE NORTH",
};

export const regionLabelsTextLower = {
    counts: "Counts of the East",
    barons: "Barons of the March",
    patricians: "Patricians of the Coast",
    grandees: "Grandees of the South",
    chiefs: "Chiefs of the North",
};

export const regionNames = {
    counts: "Counts",
    barons: "Barons",
    patricians: "Patricians",
    grandees: "Grandees",
    chiefs: "Chiefs",
}

export const statDisplayText = {
    authority: "Authority",
    stability: "Stability",
    treasury: "Treasury",
    rebellionVictory: "Rebellion Victory",
    rebellionDefeat: "Rebellion Defeat",
    trade: "Trade",
    military: "Military",
    farming: "Farming",
    faith: "Faith",
    defiance: "Defiance",
}