const data = {
    buildings: [
      {
        region_id: "barons",
        build_state: "FUNDED",
        definition: {
          id: "monument",
          reward: { value: 1, stat_id: "authority" },
        },
      },
    ],
    message_type: "BUILDING_STATE",
  };
  
  export default data;
  