export default {
  regions: [
    {
      id: "barons",
      name: "Barons",
      territory: "the March",
      stats: [
        { id: "trade", value: 5 },
        { id: "military", value: 6 },
        { id: "farming", value: 5 },
        { id: "faith", value: 6 },
        { id: "defiance", value: 4 },
      ],
      scheme_data: null,
      current_scheme_step: null,
      has_won: false,
    },
    {
      id: "chiefs",
      name: "Chiefs",
      territory: "the North",
      stats: [
        { id: "trade", value: 3 },
        { id: "military", value: 7 },
        { id: "farming", value: 3 },
        { id: "faith", value: 0 },
        { id: "defiance", value: 3 },
      ],
      scheme_data: null,
      current_scheme_step: null,
      has_won: false,
    },
    {
      id: "counts",
      name: "Counts",
      territory: "the East",
      stats: [
        { id: "trade", value: 4 },
        { id: "military", value: 5 },
        { id: "farming", value: 7 },
        { id: "faith", value: 4 },
        { id: "defiance", value: 3 },
      ],
      scheme_data: null,
      current_scheme_step: null,
      has_won: false,
    },
  ],
  message_type: "REGION_STATE",
};
