export default {
  choices: [
    {
      text: "Search for a witness to defend Chief asdfadf.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Help the Inquisition gather evidence against asdfadf.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Suggest they pray for a good outcome.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
  ],
  variants: [
    { variant_type: "MONARCH'S CHOICE", target: 0 },
    { variant_type: "VETO", target: -1 },
    { variant_type: "SWING VOTES", target: -1 },
    { variant_type: "MONARCH'S GOLDEN CHOICE", target: -1 },
  ],
  region_id: null,
  is_voting_open: true,
  message_type: "VOTE_STEP",
};
