// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";

class createNavStore {
    path = "/game"
    navigate = (x) => {
        this.path = x;
        this.previousPath = null;
    }
    clear = () => {
        this.path = "/game"
        this.previousPath = null;
    }
    push = (newPath) => {
        this.previousPath = this.path;
        this.path = newPath;
    }
    pop = () => {
        if (this.previousPath != null) {
            this.path = this.previousPath;
            this.previousPath = null;
        }
    }
    constructor() {
        this.previousPath = null;
        makeAutoObservable(this)
    }
    
}

const navStore = new createNavStore();
export default navStore;
