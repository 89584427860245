/* eslint-disable jsx-a11y/alt-text */
import styles from "./style.module.scss";

import { observer } from "mobx-react";
import Container from "components/container";

export default observer(function WishlistButton(props) {
  return (
    <Container>
        <button className={styles.wishlistButton} onClick={()=>{window.open("https://bit.ly/GM-KOTC");}}>
            <h1 className={styles.wishlistHeader}>
                Become the Monarch, customize your Noble & Choose your region!
            </h1>
            <h2 className={styles.wishlistSubHeader}>
                Out now on Steam: $4.99 / £3.99 / €3.99
            </h2>
        </button>
    </Container>
  );
});
