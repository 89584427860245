// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
	const storedJson = localStorage.getItem(name)
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}
class createSchemeStore {
    isFirstSchemeMsg = true;
    currentScheme = null;
    description = "Backstab and threaten your way on to the Throne.";
    objective ="Raise Kingdom's Stability to at least 5 in 3 turns";
    currently = "Current Kingdom stability: 3"
    clear(){
        this.currentScheme = null;
        localStorage.setItem('schemeStore', null)

    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'schemeStore')
    }
}

const schemeStore = new createSchemeStore();
export default schemeStore;