import { letters } from 'constants';
import styles from './style.module.scss';
import lockedIcon from './icon_padlock.png'
const parseHTML = require('html-react-parser');

export default function VoteItem(props) {
  var voteRegion = props.region ? props.region : "basic";
  return (
    <>
      <div className={styles.choice} disabled={props.disabled} data-choice={props.value}>
        <div className={`${styles.lilbox} ${voteRegion}-accent-bg`} onClick={props.onClick} disabled={props.disabled} >
          <div className={styles.choiceLetter}>
            {
              props.disabled ? <img className={styles.lockedIcon} src={lockedIcon} alt={"Locked choice icon"} /> : letters[props.value]
            }
          </div>
        </div>

        <div className={`${styles.voteText} ${voteRegion}-bg `} disabled={props.disabled} onClick={props.onClick}>
          <div>
            {function(){
              // safer than `dangerouslySetInnerHTML`
              var html = parseHTML(props.text)
              if (typeof html === "string") return html
              return html.filter(e => !e.type || e.type === "i")
            }()}
          </div>
        </div>
        {
            // Only display stat changes if there aren't challenges (PT-2379)
            props.voteData?.challenges.length ? null :
              <div className={styles.choiceDetail} data-textbox="stat-changes">
                {props.voteData?.scheme_set?.scheme_goals && <span>{props.voteData?.scheme_set?.scheme_goals}</span>}
                {props.voteData?.stat_changes
                  .filter(e => !["rebellionDefeat"].includes(e.stat_id))
                  .map((x, i) => <span key={i}>
                    {x.change == "Increase" && "Raises"}
                    {x.change == "Decrease" && "Lowers"}
                    {x.change == "Both" && "Raises and lowers"}
                    {" "}
                    {x.stat_id}.
                    {" "}
                  </span>)}
              </div>
        }
        </div>
    </>
  );
}
