// import styles from './style.module.css';

export default function BasicInput(props) {
  return (
    <>
      <button
        name={props.name}
        type={props.type}
        value={props.defaultValue}
        className={props.className}
        disabled={props.disabled}
        onClick={(e) => {
          e.preventDefault();
          props?.onClick?.();
        }}
      >
        {props.children}
      </button>
    </>
  );
}
