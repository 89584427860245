import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation} from "react-router-dom";
import { configure } from "mobx";

import "bootstrap/dist/css/bootstrap.css";
import "fonts/Lato/style.scss";
import "fonts/Germania_One/style.scss";
import "fonts/Noto_Sans/style.scss";
import "globalStyle.scss";

import ChooseLogin from "pages/ChooseLogin";
import Login from "pages/Login";
import Join from "pages/Join";
import Game from "pages/Game";
import Loading from "pages/LoadingPage";
import TwitchLink from "pages/TwitchLink";

configure({
  enforceActions: "never",
});

function Root() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname === "/twitch-extension"){
            window.location.href = "https://dashboard.twitch.tv/extensions/m6b0tv19m19v6qjzvkkymnjsltho86";
        }
    });

  return (
        <Routes>
          <Route path="/" element={<ChooseLogin />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/login" element={<Login />} />
          <Route path="/join" element={<Join />} />
          <Route path="/game/*" element={<Game />} />
          <Route path="/twitch-success" element={<TwitchLink />} />
          <Route path="*" element={<ChooseLogin />} />
        </Routes>
  );
}


function App() {
    return (
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    );
}


ReactDOM.render(<App />, document.getElementById("root"));
