// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";
import EventChannel from "util/EventChannel";

class createMatchStore {
    schemeView = false;
    rebellionView = false;
    error = false;
    errorMsg = ""
    errorTitle= "An error has occured";
    forcedSyncCount= 0;    
    turn = null;
    numConnectionErrors = 0;
    events = new EventChannel();
    
    constructor() {
        makeAutoObservable(this)
    }
    clear = () => {
        this.schemeView = false;
        this.rebellionView = false;
        this.error = false;
        this.errorMsg = "";
        this.errorTitle= "An error has occured";
        this.forcedSyncCount= 0;
        this.turn = null;
        this.numConnectionErrors = 0;
        this.events.on("ERROR_MODAL", ShowError);
    }
}

// TODO - Laurie - this was pulled from its own file and moved here when the events were moved to the messageHandlerStore
// I have no idea what its intention was, and it looks mental.
const ShowError = (e) =>{
    matchStore.error = !matchStore.error;
};

const matchStore = new createMatchStore();

export default matchStore;