const data = {
  buildings: [
    {
      region_id: "barons",
      build_state: "FUNDING",
      definition: { id: "grandbazaar", reward: { value: 1, stat_id: "trade" } },
    },
    {
      region_id: "barons",
      build_state: "FUNDING",
      definition: { id: "aqueduct", reward: { value: 1, stat_id: "farming" } },
    },
    {
      region_id: "chiefs",
      build_state: "FUNDING",
      definition: { id: "fortress", reward: { value: 1, stat_id: "military" } },
    },
    {
      region_id: "chiefs",
      build_state: "FUNDING",
      definition: { id: "aqueduct", reward: { value: 1, stat_id: "farming" } },
    },
    {
      region_id: "counts",
      build_state: "FUNDING",
      definition: {
        id: "monument",
        reward: { value: 1, stat_id: "authority" },
      },
    },
    {
      region_id: "counts",
      build_state: "FUNDING",
      definition: { id: "cathedral", reward: { value: 1, stat_id: "faith" } },
    },
  ],
  message_type: "BUILDING_STATE",
};

export default data;
