// import styles from './style.module.css';
export default function BasicInput(props) {
    return <>
        <input
            name={props.name}
            type={props.type}
            value={props.defaultValue}
            className={props.className}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            onInput={(e) => {props?.onInput?.(e)}}
            onChange={(event)=> {
                const target = event.target;
                props.setValue(target.value)                
            }}    
        />
    </>
}