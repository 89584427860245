import { makeAutoObservable } from "mobx";
// the mobx store, all it does is return an object
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
    const storedJson = localStorage.getItem(name)
    if (storedJson) {
        set(_this, JSON.parse(storedJson))
    }
    autorun(() => {
        const value = toJS(_this)
        localStorage.setItem(name, JSON.stringify(value))
    })
}

class createKingdomStore {
    kingdom = {}
    clear = () => {
        this.kingdom = {}
        localStorage.setItem('kingdomStore', null)
    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'kingdomStore')
    }
}

const kingdomStore = new createKingdomStore();
export default kingdomStore;