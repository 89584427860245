export default {
    "choices": [
      {
        "text": "Sack the Archbishop and replace him with one who'll keep his mouth shut.",
        "locked": false,
        "stat_changes": [
          {
            "stat_id": "defiance",
            "net_change": 1,
            "change": "Both"
          },
          {
            "stat_id": "stability",
            "net_change": -2,
            "change": "Decrease"
          },
          {
            "stat_id": "faith",
            "net_change": 2,
            "change": "Increase"
          }
        ],
        "chosen": false,
        "scheme_set": null
      },
      {
        "text": "Order the Archbishop to apologize.",
        "locked": false,
        "stat_changes": [],
        "chosen": false,
        "scheme_set": null
      },
      {
        "text": "Stay silent on the matter.",
        "locked": false,
        "stat_changes": [
          {
            "stat_id": "defiance",
            "net_change": -1,
            "change": "Decrease"
          },
          {
            "stat_id": "faith",
            "net_change": -2,
            "change": "Decrease"
          },
          {
            "stat_id": "authority",
            "net_change": 1,
            "change": "Increase"
          }
        ],
        "chosen": false,
        "scheme_set": null
      },
      {
        "text": "Declare the Archbishop has the King's full support.",
        "locked": false,
        "stat_changes": [
          {
            "stat_id": "defiance",
            "net_change": 1,
            "change": "Both"
          },
          {
            "stat_id": "faith",
            "net_change": -2,
            "change": "Decrease"
          },
          {
            "stat_id": "stability",
            "net_change": 1,
            "change": "Increase"
          }
        ],
        "chosen": false,
        "scheme_set": null
      }
    ],
    "variants": [
      {
        "variant_type": "MONARCH'S CHOICE",
        "target": -1
      },
      {
        "variant_type": "VETO",
        "target": -1
      }
    ],
    "region_id": null,
    "is_voting_open": true,
    "message_type": "VOTE_STEP"
  }