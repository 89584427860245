import { observer } from "mobx-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./style.module.scss";
import banner from "./feast_logoscenter.png";

export default observer(function TwitchLink(props) {
    function GetUsername(){
        const [searchParams] = useSearchParams();
        return  searchParams.get("username");
    }

    return (
        <div className={styles.twitchLinkMain}>
                <div className={styles.twitchLinkContainer}>
                    <img src={banner}/>
                    <h1>
                        Twitch account <mark>{GetUsername()}</mark> connected!
                    </h1>
                    <p>Your customised Noble will now appear in any Twitch streams of King of the Castle you join.</p>
                    <p>As a premium player your Noble will appear more often in all KOTC games.</p>
                    <p>If you stream KOTC on Twitch the game will now pick up any commands from your chat.</p>
                    <p>Our <b>Twitch Extension</b> allows players in your Twitch chat to see their Region, Wealth and Schemes:</p>
                    <div className={styles.twitchLinkButton}>
                        <a href="https://www.kotc.app/twitch-extension" target="_blank">TWITCH EXTENSION FOR STREAMERS</a>
                    </div>
                    <p>Don't forget you can also host <b>Party Games</b> and have 3 to 24 Nobles join for free at <a href="https://www.kotc.app" target="_blank">kotc.app</a>
                    </p>
                    <p><b>You may now close this page at any time.</b></p>
                </div>
    </div>
    );
});