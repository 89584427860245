export default {
  choices: [
    {
      text: "Blood Ritual. (Goal: Lower Others' Farming)",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: {
        scheme_id: "counts_blood_ritual",
        scheme_goals:
          "Lower other regions' total Farming, Lower the Kingdom's Stability",
      },
    },
    {
      text: "Ascension. (Goal: Lower Others' Faith)",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: {
        scheme_id: "counts_ascension",
        scheme_goals:
          "Lower other regions' total Faith, Raise the Kingdom's Authority",
      },
    },
    {
      text: "Possession. (Goal: Lower Own Defiance)",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: {
        scheme_id: "counts_possession",
        scheme_goals:
          "Lower other regions' total Defiance, Lower other regions' total Faith",
      },
    },
  ],
  variants: [
    { variant_type: "MONARCH'S CHOICE", target: -1 },
    { variant_type: "VETO", target: -1 },
  ],
  region_id: "chiefs",
  is_voting_open: true,
  message_type: "VOTE_STEP",
};
