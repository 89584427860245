import { makeAutoObservable } from "mobx";
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
	const storedJson = localStorage.getItem(name)
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}
class createRegionStore {
    regions = {}
    clear = () => {
        this.regions = {}
        localStorage.setItem('regionStore', null)

    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'regionStore')
    }
}

const regionStore = new createRegionStore();
export default regionStore;