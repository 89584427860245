import accountStore from "stores/accountStore";
import matchStore from "stores/matchStore";
import wsStore from "stores/wsStore";
import navStore from "stores/navStore";
import messageHandlerStore from "stores/messageHandlerStore";
import regionStore from "stores/regionStore";
import rebellionStore from "stores/rebellionStore"
import schemeStore from "stores/schemeStore"
import clearData from "actions/clearData";

export default function route(messageObj) {
  
  if(accountStore.isDebugEnabled){
      messageObj['received_timestamp'] = Date.now();
      accountStore.log.push(messageObj);
  }
  messageHandlerStore.events.emit(messageObj.message_type, messageObj);

  //this is disconnect timer. shut off game if it's left open
  accountStore.dcTimer = new Date().getTime();
  clearTimeout(accountStore.dcTimeOut);

  accountStore.dcTimeOut = setTimeout(() => {
    console.log("Forced Disconnect!")
    navStore.navigate("/");
    clearData();
    wsStore.client?.deactivate();
  }, 27000000);
}
