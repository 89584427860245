export default {
  choices: [
    {
      text: "Hire mercenaries from across the Ghost Sea.",
      locked: true,
      stat_changes: [
        { stat_id: "military", net_change: 2, change: "Increase" },
        { stat_id: "trade", net_change: -2, change: "Decrease" },
      ],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Conscript a mass of peasant levies.",
      locked: true,
      stat_changes: [
        { stat_id: "military", net_change: 2, change: "Increase" },
        { stat_id: "farming", net_change: -2, change: "Decrease" },
      ],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Call upon a Holy Order of the Ninth to join the cause.",
      locked: true,
      stat_changes: [
        { stat_id: "military", net_change: 1, change: "Increase" },
        { stat_id: "faith", net_change: -2, change: "Decrease" },
      ],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "The existing Marcher army will have to be enough.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
  ],
  variants: [
    { variant_type: "MONARCH'S CHOICE", target: -1 },
    { variant_type: "VETO", target: -1 },
    { variant_type: "SWING VOTES", target: -1 },
    { variant_type: "MONARCH'S GOLDEN CHOICE", target: -1 },
  ],
  region_id: "chiefs",
  is_voting_open: true,
  message_type: "VOTE_STEP",
};
