export default {
  rebellions: [
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "barons",
    },
  ],
  message_type: "REBELLION_STATE",
};
const rebelion2 = {
  rebellions: [
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "barons",
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "counts",
    },
  ],
  message_type: "REBELLION_STATE",
};

const rebelions3 = {
  rebellions: [
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: "4de49619-575e-4971-a5c1-e2f999f35955",
      rebel_count: 1,
      region_id: "barons",
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "chiefs",
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: "1a3a29f1-b967-4726-857b-dac7e691fe05",
      rebel_count: 1,
      region_id: "counts",
    },
  ],
  message_type: "REBELLION_STATE",
};

const inprogress = {
  rebellions: [
    {
      has_ended: false,
      has_failed: false,
      is_gathering: false,
      is_main: true,
      is_ongoing: true,
      leader: "4de49619-575e-4971-a5c1-e2f999f35955",
      rebel_count: 1,
      region_id: "barons",
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "chiefs",
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: false,
      is_main: false,
      is_ongoing: true,
      leader: "1a3a29f1-b967-4726-857b-dac7e691fe05",
      rebel_count: 1,
      region_id: "counts",
    },
  ],
  message_type: "REBELLION_STATE",
};
