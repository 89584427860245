import matchStore from "stores/matchStore";
import voteStore from "stores/voteStore";
import accountStore from "stores/accountStore";
import authStore from "stores/authStore";
import buildingStore from "stores/buildingStore";
import rebellionStore from "stores/rebellionStore";
import regionStore from "stores/regionStore";
import kingdomStore from "stores/kingdomStore";
import schemeStore from "stores/schemeStore";
import navStore from "stores/navStore";
import wsStore from "stores/wsStore";
import pollStore from "stores/pollStore";
import {requestSync, sendJoinRequest} from "actions/voting/sendVote";
import { makeAutoObservable } from "mobx";
import EventChannel from "../util/EventChannel";

class createMessageHandlerStore {
    events = new EventChannel();
    constructor() {
        makeAutoObservable(this);
        this.events.on("POLL_STATE", HandlePollSate);
        this.events.on("FULL_STATE", HandleStateSync);
        this.events.on("VOTE_STEP", StartVote);
        this.events.on("SCHEME_STEP", StartSchemeVote);
        this.events.on("BUILDING_STATE", StartBuildingVote);
        this.events.on("REBELLION_STATE", HandleRebellionMessage);
        this.events.on("REGION_STATE", HandleRegionMessage);
        this.events.on("KINGDOM_STATE", HandleKingdomMessage);
        this.events.on("TURN_STATE", HandleTurnInfo);
        this.events.on("GAME_OVER", HandleGameOver);
        this.events.on("NOBLE_STATE", HandleNobleState);
        this.events.on("PAUSE", HandlePause);
    }
    clear = () => {
        this.events = new EventChannel();
    }
}

const StartVote = (e) => {
    const data = e.detail;

    // Handle region-specific voting.
    if (data.region_id && accountStore.region !== data.region_id) {
        console.log("Received message to open a poll, but for another region. Skipping.");
        return;
    }

    // Handle Polls which are not yet open (e.g. - during the vote setup phase)
    if (!data.is_voting_open) {
        voteStore.isVoteAlmostOpen = true;
        voteStore.isVoteOpen = false;
        return;
    }

    // Prevent regions from voting if they are in rebellion, unless it is a region-specific vote for the rebels, or a successor vote
    if (rebellionStore.isRebelling && !data.region_id && !data.is_successor_vote) {
        console.log("Received message to open a poll, but region is rebelling. Skipping.");
        return;
    }

    // Check to see the player has voted before.
    // We do this by using a big string of all possible vote choices as a sort of hash.
    var choicesKey = [];
    for (var choice of data.choices) {
        choicesKey.push(choice.text)
    }
    const choicesKeyString = JSON.stringify(choicesKey);

    // Check against what we had before
    // If it's a completely new vote, clear the store
    if (pollStore.choiceKey !== choicesKeyString) {
        pollStore.clear();
        pollStore.choiceKey = choicesKeyString;
    }

    // Players may *only* vote more than once if the SWING VOTE variant is enabled. 
    if (pollStore.nobles[authStore.decodedLoginToken.user_id] != undefined && !voteStore.variants["SWING VOTES"]) {
        console.log("Player has voted before. Skipping vote");
        return;
    }

    // Default to false, cause if it gets this far, it's false
    // TODO - Laurie - Check how this attribute is used and what its intention is.
    voteStore.isVoteAlmostOpen = false;
    voteStore.choices = data.choices;
    voteStore.variants = {};
    voteStore.region = data.region_id;
    voteStore.timeStamp = new Date().getTime();

    if (data.variants) {
        for (var item of data?.variants) {
            if (item.variant_type === "SWING VOTES")
                voteStore.variants[item.variant_type] = item.is_active_toggled_variant;
            else
                voteStore.variants[item.variant_type] = item.target;
        }
    }

    if (data.region_id) {
        StartSchemeVote();
        return;
    }

    // TODO - Laurie - Check this - wtf is it doing?
    // I suspect it's something to do with messages incoming that are in the vote-setup phase.
    if (!voteStore.isVoteOpen) {
        voteStore.isVoteOpen = true;
        voteStore.mode = "vote";
        navStore.navigate("/game/vote");
    }
    CheckIfVoteOver();
};

const StartSchemeVote = (e) => {
    if (!voteStore.isVoteOpen) {
        voteStore.mode = "scheme";
        voteStore.isVoteOpen = true;
        navStore.navigate("/game/scheme");
    }
    CheckIfVoteOver();
};

const StartBuildingVote = (e) => {
    // Exit early if we're not in building-mode, but we receive a full-state message containing (empty) building information
    if(voteStore.mode !== "building" && e.detail.buildings.length === 0) {
        return;
    }

    const data = e.detail;
    buildingStore.buildings = data.buildings;
    buildingStore.mappedBuildings = {};
    buildingStore.regions = [];
    buildingStore.buildingCount = 0;
    for (var i in buildingStore.buildings) {
        var item = buildingStore.buildings[i];
        item.index = i;
        if (item.build_state !== "FUNDING") {
            continue;
        }
        if (!buildingStore.regions.includes(item.region_id)) {
            buildingStore.regions.push(item.region_id);
        }
        if (!buildingStore.mappedBuildings[item.region_id]) {
            buildingStore.mappedBuildings[item.region_id] = [];
        }
        if (item.build_state === "FUNDING") {
            buildingStore.mappedBuildings[item.region_id].push(item);
            buildingStore.buildingCount += 1;
        }
    }

    if (buildingStore.buildingCount === 0) {
        voteStore.clear();
    } else if (!voteStore.isVoteOpen) {
        voteStore.isVoteOpen = true;
        voteStore.mode = "building";
        navStore.navigate("/game/building");
    }
};

const HandleRebellionMessage = (e) => {
    const data = e.detail;
    if (data.rebellions.length === 0) {
        return
    }

    const newRebellionData = {};
    for (let item of data.rebellions) {
        //if rebellion data is empty, then slap in that data
        //or if there is data but it's an old rebellion. This keeps duplicates from happening
        if(!newRebellionData[item.region_id] || newRebellionData[item.region_id].has_failed || newRebellionData[item.region_id].has_ended) {
            newRebellionData[item.region_id] = item;
        }
    }

    rebellionStore.rebellions = [];
    //push things into the store
    for (let i in newRebellionData) {
        rebellionStore.rebellions.push(newRebellionData[i]);
    }

    var regions = {};
    //creates a list of regions that have rebellion data
    rebellionStore.isRebelling = false;
    for (let item of rebellionStore.rebellions) {
        regions[item.region_id] = true;
        if (item.region_id === accountStore.region) {
            rebellionStore.hasVotedToRebel = item.rebel_ids.includes(authStore.decodedLoginToken.user_id);
            rebellionStore.isRebelling = item.is_ongoing;
        }
    }

    //Go through each region and check if it has rebellion data
    //and if doesn't have rebellion data, then create new rebellion data for it
    for (let i in regionStore.regions) {
        if (!regions[i]) {
            rebellionStore.rebellions.push({
                has_ended: false,
                has_failed: false,
                is_gathering: false,
                is_main: false,
                is_ongoing: false,
                leader: null,
                rebel_count: 0,
                region_id: i
            });
        }
    }
};

const HandleRegionMessage = (e) => {
    const data = e.detail;

    for (let item of data.regions) {
        regionStore.regions[item.id] = item;
        if (item?.has_won) { // disconnect game if game is over
            wsStore.client?.deactivate();
        }
        if (item.id !== accountStore.region) {
            continue;
        }
        if (item.scheme_data) {
            schemeStore.currentScheme = item.scheme_data.scheme_title;
            schemeStore.objective = item.scheme_goal_text;

        } else {
            schemeStore.currentScheme = null;
        }
    }
};

const HandleKingdomMessage = (e) => {
    const data = e.detail;
    if(!data.kingdom){
        return;
    }

    kingdomStore.kingdom = data.kingdom;
};

const CheckIfVoteOver = () => {
    var isVoteOver = false;
    for (var choice of voteStore.choices) {
        if (choice.chosen) {
            isVoteOver = true;
        }
    }

    if (isVoteOver) {
        voteStore.clear();
    }
};

const HandleTurnInfo = (e) => {
    const data = e.detail;
    matchStore.turn = data?.turn?.turn_number + 1;
};

const HandleStateSync = (e) => {
    const data = e.detail;
    for (var msg of data.messages) {
        messageHandlerStore.events.emit(msg.message_type, msg);
    }
};

const HandlePollSate = (e) => {
    const data = e.detail;
    pollStore.nobles = {};
    for (var id in data.poll) {
        pollStore.nobles[id] = data.poll[id]['value'];
    }
};

const HandleGameOver = (e) => {
    kingdomStore.kingdom.is_early_gameover = true;
    wsStore.client?.deactivate();
};

const HandleNobleState = (e) => {
    const data = e.detail;
    const noble = data.nobles.find(e => e.id === authStore.decodedLoginToken.user_id);

    if(!noble){
        console.log(`[WARN] Could not find noble with id '${authStore.decodedLoginToken.user_id}' Re-issuing !join`);

        // This can happen (and not be an error) when:
        //   - Multiple tabs are open, for the same player, after loading into the noble-join screen.
        //   - One tab closes.
        //       - This socket drop is detected by the server, which issues as !leave on the player's behalf
        //       - The !leave is handled by the game, which updates the noble store and pushes the new message without this noble
        //       - But, this page (which hasn't closed its socket) still thinks it's happily connected as the OG noble.
        // 
        // We just re-issue a !join here, if that happens.
        
        sendJoinRequest(accountStore.pronouns);
        return;
    }

    if(accountStore.region == null)
        requestSync();

    accountStore.pendingJoinTeamRequest = false;
    accountStore.region = noble.region_id;
    accountStore.money = noble.wealth;
    accountStore.fullName = noble.full_name;
};

const HandlePause = (e) => {
    kingdomStore.kingdom.is_paused = e.detail.is_paused;
    if (kingdomStore.kingdom.is_paused) {
        navStore.push("/game/pause");
    }
};

const messageHandlerStore = new createMessageHandlerStore();

//passthrough for cypress e2e testing
if (window.Cypress) {
    window.events = messageHandlerStore.events;
}
export default messageHandlerStore;
