export default {
  rebellions: [
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "counts",
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: false,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "chiefs",
    },
    {
      has_ended: true,
      has_failed: false,
      is_gathering: false,
      is_main: false,
      is_ongoing: false,
      leader: null,
      rebel_count: 0,
      region_id: "barons",
    },
  ],
  message_type: "REBELLION_STATE",
};
