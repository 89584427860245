// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
	const storedJson = localStorage.getItem(name)
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
        _this.clearFlagsOnLoad();
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}

class createVoteStore {
    isVoteOpen = false;
    isVoteAlmostOpen = false;
    mode = "";
    choices = []
    variants = {}
    region = null;
    timeStamp = null;
    clearFlagsOnLoad = ()=>{
        this.isVoteOpen = false;
    }
    clear = () => {
        this.choices = [];
        this.variants = {};
        this.mode = ""
        this.isVoteOpen = false;
        localStorage.setItem('voteStore', null)

    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'voteStore')
    }
}
const voteStore = new createVoteStore();
window.voteStore = voteStore;
export default voteStore;