import Button from "../basicButton";
import styles from "./style.module.scss";
import accountStore from "../../stores/accountStore";
import cx from "classnames";

export default function PronounChoiceButton(props) {
    var pronoun = props.pronounKey;
    var enabled = props.enabled;
    return(
        <Button
            type="button"
            className={cx(styles.pronoun, {
                [styles.active]:enabled
            })}
            onClick={() => {
                accountStore.pronouns = pronoun;
            }}
        >
            {props.displayString}
        </Button>
    )
}