export default {
  choices: [
    {
      text: "Ragna, from the Barons Of The March",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Titus, from the Counts Of The East",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
  ],
  variants: [
    { variant_type: "MONARCH'S CHOICE", target: -1 },
    { variant_type: "VETO", target: -1 },
    { variant_type: "SWING VOTES", target: -1 },
    { variant_type: "MONARCH'S GOLDEN CHOICE", target: -1 },
    { variant_type: "REVERSE VOTING", target: -1 },
  ],
  region_id: null,
  is_voting_open: true,
  message_type: "VOTE_STEP",
};
