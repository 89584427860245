import { observer } from "mobx-react";
import VoteItem from "components/voteItem";
import voteStore from "stores/voteStore";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { sendVoteRequest } from "actions/voting/sendVote";
import { letters } from "constants";

export default observer(function VoteInput() {
  const navigate = useNavigate();
  if (!voteStore.isVoteOpen) {
    navigate("/game");
  }

  return (
    <div className={styles.voteContainer}>
      <div className={styles.headerTitle}>Voting is open!</div>
      <hr />
      {
        voteStore.choices.map((x, i) => {
          return (
            <VoteItem
              key={i}
              value={i}
              text={x.text}
              voteData={x}
              disabled={i == voteStore?.variants?.VETO || x.locked}
              onClick={() => {
                if((i == voteStore?.variants?.VETO) || x.locked){
                  return;
                }
                sendVoteRequest(letters[i]);
                if(!voteStore?.variants?.["SWING VOTES"]){
                    voteStore.clear();
                }
              }}
            />
          );
        }
        )
      }
    </div>
  );
});
