export default {
  choices: [
    {
      text: "Promise riches to those who move or enlist from the North.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Set aside bountiful farmland for Northern peasants.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Give <i>actual</i> money to those who join the Southern army.",
      locked: false,
      stat_changes: [
        { stat_id: "wealth", net_change: -200, change: "Decrease" },
        { stat_id: "military", net_change: 2, change: "Increase" },
        { stat_id: "stability", net_change: -1, change: "Decrease" },
      ],
      chosen: false,
      scheme_set: null,
    },
    {
      text: "Use the spies to improve our tactical military position instead.",
      locked: false,
      stat_changes: [],
      chosen: false,
      scheme_set: null,
    },
  ],
  variants: [
    { variant_type: "MONARCH'S CHOICE", target: -1 },
    { variant_type: "VETO", target: -1 },
  ],
  region_id: "chiefs",
  is_voting_open: true,
  message_type: "VOTE_STEP",
};
