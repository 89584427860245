import { makeAutoObservable } from "mobx";
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
	const storedJson = localStorage.getItem(name)
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}
class createPollStore {
    nobles = {}
    choiceKey = "";
    clear = () => {
        this.nobles = {};
        this.choiceKey = "";
        localStorage.setItem('pollStore', null)

    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'pollStore')
    }
}

const pollStore = new createPollStore();
export default pollStore;