import styles from './style.module.scss';
import flag from "./IMAGE-StandardButton.png"
import disabledFlag from "./IMAGE-DisabledButton.png"
export default function BasicInput(props) {
  var appropriateFlag = props.disabled?  disabledFlag : flag ;
  return (
    <>
      <button
        name={props.name}
        type={props.type}
        value={props.defaultValue}
        className={`${styles.flag} ${props.className}`}
        disabled={props.disabled}
        onClick={(e) => {
          if(props?.type !== "submit"){
            e.preventDefault();
          }
          props?.onClick?.();
        }}
      >        
        <img src={appropriateFlag} alt="" className={styles.flagImg}/>
        {props.children}
      </button>
    </>
  );
}
