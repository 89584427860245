// the mobx store, all it does is return an object
import { makeAutoObservable } from "mobx";
import { autorun, set, toJS } from 'mobx'

export function autoSave(_this, name) {
	const storedJson = localStorage.getItem(name)
    
	if (storedJson) {
		set(_this, JSON.parse(storedJson))
	}
	autorun(() => {
		const value = toJS(_this)
		localStorage.setItem(name, JSON.stringify(value))
	})
}

class createAuthStore {
    loginToken = null;
    decodedLoginToken = null;
    gameToken = null;
    isTokenExpired(){
        return this.decodedLoginToken?.exp < Math.floor(Date.now() / 1000);
    }
    isSteamLogin(){
        var grantType = this.decodedLoginToken?.grant_type;
        if(!grantType)
            return false;

        // Grant type will be STEAM_RETAIL if the Steam account in question owns the game.
        // Or STEAM_DEMO if they (surprise, surprise) own the demo.
        return grantType === "STEAM_RETAIL" || grantType === "STEAM_DEMO" || grantType === "STEAM_WEB";
    }
    clear(){
        this.loginToken = null;
        this.decodedLoginToken = null;
        this.gameToken = null;
        localStorage.setItem('authStore', null)
    }
    constructor() {
        makeAutoObservable(this)
        autoSave(this, 'authStore')
    }
}

const authStore = new createAuthStore();

//passthrough for cypress e2e testing
if (window.Cypress) {
    window.authStore = authStore;
}

export default authStore;