import authStore from "../../stores/authStore";
import styles from "../../pages/Login/style.module.scss";
import SteamIcon from "../steamIcon";
import React from 'react';
import accountStore from "../../stores/accountStore";

export function isValidName(x) {
    // We allow all unicode characters here in the range 00C0 (À) to 00FF (ÿ)
    // Excluding two non-letter characters: \u00D7 and \u00F7
    const regexp = /^(?![\u00D7\u00F7])[0-9a-zA-Z- \u00C0-\u00FF]*$/u;
    return x && x.length && x.match(regexp);
};

export default class NameInputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: '', err: (authStore.isSteamLogin() && !isValidName(authStore.decodedLoginToken.display_name))};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        var value = event.target.value.trim();
        this.setState({
            value: value,
            err: value && !isValidName(value)
        });
        accountStore.name = value;
    }
    handleSubmit(event) {
        event.preventDefault();
    }
    render() {
        return (
            <div className={styles.inputGroup}>
                <h2>Name</h2>
                {authStore.isSteamLogin() ? <SteamIcon className={styles.steamIcon}/> : ''}
                <input
                    className={authStore.isSteamLogin() ? styles.steamNameInput : ""}
                    placeholder={"Start typing name..."}
                    maxLength={24}
                    {...(authStore.isSteamLogin() ? {defaultValue: authStore.decodedLoginToken.display_name} : {})}
                    onChange={(event)=> {
                        this.handleChange(event);
                    }}
                />
                {this.state.err ? <h4 className={styles.error}>{ "Names may only contain alphanumeric characters, dashes, and spaces." }</h4> : ""}
            </div>
        )
    }
}
