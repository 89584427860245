import TopNav from "components/topnav";
import Background from "components/background";
import Container from "components/container";
import styles from "./style.module.scss";
import gameStyles from "../Game/style.module.scss";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { leaveGame } from "actions/join";
import accountStore from "stores/accountStore";

export default observer(function LoadingPage(props) {
  const navigate = useNavigate();
  return (
    <>
      <TopNav />
      <Background />      
      <Container className={styles.container}>
        <h1>{accountStore.gameJoinCode}</h1>
        <i>{accountStore.joinGameError ? `Error - ${accountStore.joinGameError}` : "Joining game - please wait..."}</i>
        <div className={[gameStyles.quitButton, gameStyles.center].join(' ')} data-button="quit" onClick={() => {
            leaveGame();
            window.clearData();
            navigate("/");
        }}>Quit</div>
      </Container>
    </>
  );
});
