export default {
    "kingdom": {
      "id": "Kingdom",
      "is_reign_over": true,
      "stats": [
        {
          "id": "authority",
          "value": 7
        },
        {
          "id": "stability",
          "value": 4
        },
        {
          "id": "treasury",
          "value": 8949
        },
        {
          "id": "rebellionVictory",
          "value": 0
        },
        {
          "id": "rebellionDefeat",
          "value": 0
        }
      ]
    },
    "message_type": "KINGDOM_STATE"
  }
