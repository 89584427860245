import TopNav from "components/topnav";
import Background from 'components/background';
import Container from "components/container";
import styles from './style.module.scss';
export default function basicLayout(props) {
  return <>    
    <Background />
    <Container className={styles.container}>
      {props.children}
    </Container>
  </>
}