export default {
    choices: [
      {
        text: "Host a lavish parade with jugglers and fountains of wine.",
        locked: false,
        stat_changes: [
          { stat_id: "treasury", net_change: -1000, change: "Decrease" },
          { stat_id: "stability", net_change: 1, change: "Increase" },
        ],
        chosen: false,
      },
      {
        text: "Proceed with a religious ceremony that honours the Ninth God.",
        locked: false,
        stat_changes: [{ stat_id: "faith", net_change: 2, change: "Increase" }],
        chosen: false,
      },
      {
        text: "Throw the Monarch into the river.",
        locked: false,
        stat_changes: [
          { stat_id: "stability", net_change: 1, change: "Increase" },
          { stat_id: "authority", net_change: -1, change: "Decrease" },
        ],
        chosen: false,
      },
      {
        text: "Outdated traditions! There's no need for a coronation at all!",
        locked: false,
        stat_changes: [
          { stat_id: "authority", net_change: -1, change: "Decrease" },
        ],
        chosen: false,
      },
    ],
    variants: [
      { variant_type: "MONARCH'S CHOICE", target: -1 },
      { variant_type: "VETO", target: 1 },
    ],
    region_id: null,
    is_voting_open: true,
    message_type: "VOTE_STEP",
  };
  
  