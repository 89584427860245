export default {
  rebellions: [
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      region_id: "barons",
      rebel_ids: [],
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: true,
      is_main: false,
      is_ongoing: false,
      leader: null,
      region_id: "chiefs",
      rebel_ids: [],
    },
    {
      has_ended: false,
      has_failed: false,
      is_gathering: false,
      is_main: true,
      is_ongoing: true,
      leader: "159fc362-1b40-4b23-bdaf-36516468057d",
      region_id: "counts",
      rebel_ids: [
        "159fc362-1b40-4b23-bdaf-36516468057d",
        "9b0ca92d-33ed-42e5-95ba-118defdfd148",
      ],
    },
  ],
  message_type: "REBELLION_STATE",
};
