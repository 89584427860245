export default {
  regions: [
    {
      id: "barons",
      name: "Barons",
      territory: "the March",
      stats: [
        { id: "trade", value: 5 },
        { id: "military", value: 8 },
        { id: "farming", value: 3 },
        { id: "faith", value: 5 },
        { id: "defiance", value: 10 },
      ],
      scheme_data: {
        scheme_id: "barons_modernization",
        scheme_title: "Modernization",
        progress: 1,
        scheme_goal: 8,
        ending: 0,
      },
      current_scheme_step: {
        stage: 1,
        ending: 0,
        direction: "Lower",
        grouping: "Other",
        stat: {
          id: "military",
          display_name: "Military",
          min_value: 0,
          max_value: 10,
          name: "Military",
          hide_flags: 0,
        },
      },
      has_won: false,
    },
    {
      id: "chiefs",
      name: "Chiefs",
      territory: "the North",
      stats: [
        { id: "trade", value: 3 },
        { id: "military", value: 5 },
        { id: "farming", value: 2 },
        { id: "faith", value: 0 },
        { id: "defiance", value: 8 },
      ],
      scheme_data: {
        scheme_id: "chiefs_intimidation",
        scheme_title: "Intimidation",
        progress: 2,
        scheme_goal: 1000,
        ending: 0,
      },
      current_scheme_step: {
        stage: 2,
        ending: 0,
        direction: "Lower",
        grouping: "Kingdom",
        stat: {
          id: "treasury",
          display_name: "Treasury",
          min_value: 0,
          max_value: 2147483647,
          name: "Treasury",
          hide_flags: 0,
        },
      },
      has_won: false,
    },
    {
      id: "counts",
      name: "Counts",
      territory: "the East",
      stats: [
        { id: "trade", value: 5 },
        { id: "military", value: 5 },
        { id: "farming", value: 6 },
        { id: "faith", value: 4 },
        { id: "defiance", value: 7 },
      ],
      scheme_data: {
        scheme_id: "counts_possession",
        scheme_title: "Possession",
        progress: 1,
        scheme_goal: 4,
        ending: 0,
      },
      current_scheme_step: {
        stage: 1,
        ending: 0,
        direction: "Lower",
        grouping: "Other",
        stat: {
          id: "defiance",
          display_name: "Defiance",
          min_value: 0,
          max_value: 10,
          name: "Defiance",
          hide_flags: 0,
        },
      },
      has_won: true,
    },
  ],
  message_type: "REGION_STATE",
};
