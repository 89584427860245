export default {
    "buildings": [
      {
        "id": "3-chiefs-library",
        "region_id": "chiefs",
        "build_state": "FUNDING_FAILED",
        "command_char": "B",
        "definition": {
          "id": "library",
          "reward": {
            "value": 1,
            "stat_id": "stability"
          }
        }
      },
      {
        "id": "3-counts-aqueduct",
        "region_id": "counts",
        "build_state": "FUNDING_FAILED",
        "command_char": "C",
        "definition": {
          "id": "aqueduct",
          "reward": {
            "value": 1,
            "stat_id": "farming"
          }
        }
      },
      {
        "id": "3-counts-library",
        "region_id": "counts",
        "build_state": "FUNDING_FAILED",
        "command_char": "D",
        "definition": {
          "id": "library",
          "reward": {
            "value": 1,
            "stat_id": "stability"
          }
        }
      },
      {
        "id": "3-patricians-library",
        "region_id": "patricians",
        "build_state": "FUNDING_FAILED",
        "command_char": "F",
        "definition": {
          "id": "library",
          "reward": {
            "value": 1,
            "stat_id": "stability"
          }
        }
      }
    ],
    "message_type": "BUILDING_STATE"
  }