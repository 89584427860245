import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

// Stores
import matchStore from "stores/matchStore";
import accountStore from "stores/accountStore";
import voteStore from "stores/voteStore";
import navStore from "stores/navStore";
import buildingStore from "stores/buildingStore";
import wsStore from "stores/wsStore";
import kingdomStore from "stores/kingdomStore";

import BasicLayout from "components/basicLayout";

// Pages
import NobleInfo from "pages/NobleInfo";
import BuildingsInput from "pages/BuildingsInput";
import VoteInput from "pages/VoteInput";
import SchemeInput from "pages/SchemeInput";
import RebellionInput from "pages/RebellionInput";
import SuccessorInput from "pages/SuccessorVote";
import SchemeInfo from "pages/SchemeInfo";
import Pause from "pages/Pause";

import ErrorModal from "components/errorModal";
import WishlistButton from "components/wishlistButton";

// Styles and resources
import "./genericStyles.module.scss";
import styles from "./style.module.scss";
import banner from "./BUTTON-code.png";

import DebugMenu from "Debug/index";

import NobleInfoHeader from "components/nobleInfoHeader";
import LoadingScreen from "pages/LoadingPage"

import { joinGame, leaveGame } from "actions/join";
import { requestSync } from "actions/voting/sendVote"
import { clearAllData} from "actions/clearData";
import visibilityChange from "util/visibilityChange";
import authStore from "../../stores/authStore";
import Container from "../../components/container";

visibilityChange((isVisible) => {
  if (isVisible && wsStore.client?.isConnected) {
    requestSync();
  }
});

window.clearData = () => {
  clearAllData();
};

export default observer(function Game() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // This decides if things try to reconnect or kick you out to the login page
    if (!wsStore.client && accountStore.gameJoinCode && accountStore.gameJoinCode.length) {
      console.log(`Found existing game code - ${accountStore.gameJoinCode}`);
      navStore.path = "/game";

      if(!kingdomStore?.kingdom?.is_early_gameover){
        joinGame()
          .then((data) => {
              if (data.error || accountStore.joinGameError) {
                console.log("Error getting token to join game. Probably attempting to reconnect with an old game code. Returning to home.");
                clearAllData();
                navigate("/");
            }
        });
      }

      // Seems a bit weird to navigate to the game page here, but the nav will leave sub pages like schemes, votes, building, rebellion pages etc.
      navigate(navStore.path);
    } else if (!wsStore.client || !accountStore.gameJoinCode) {
      window.location.href = "/"
    }

    // Decide if the user is going to be forced to view the /building page
    if (buildingStore.buildingCount && navStore.path == "/game") {
      navStore.path = "/game/building";
      navigate(navStore.path);
      return;
    }

    // Decide if the user is going to be forced to view the /vote page
    if (voteStore.isVoteOpen && navStore.path == "/game") {
      if(voteStore.mode == "scheme"){
          navStore.path = "/game/scheme";
      }
      else {
          navStore.path = "/game/vote";
      }
      navigate(navStore.path);
    }

    // Make sure and close up shop after isVoteOpen is set to false
    if (
      !voteStore.isVoteOpen
      && ["/game/scheme", "/game/building", "/game/vote"].includes(navStore.path)
    ) {
      navStore.path = "/game";
      navigate(navStore.path);
    }

    // Handle other generic navigation
    if (navStore.path && navStore.path !== location.pathname) {
      navigate(navStore.path);
    }
  }, [navStore.path, voteStore.isVoteOpen]);
  if (!wsStore.client && !kingdomStore?.kingdom.is_early_gameover) {
    return <LoadingScreen />
  }
  return (
    <>
      {matchStore.error && <ErrorModal />}
      {accountStore.isDebugEnabled && <DebugMenu />}

      <div className={styles.lobbyCode}>
        <div className={styles.lobbyCodeTitle}>
          LOBBY CODE
          <img src={banner} className={styles.codeBanner} alt="" />
        </div>
        <div className={styles.lobbyCodeValue}>
          {accountStore.gameJoinCode || "XXXX"}
        </div>
        <div className={styles.quitButton} data-button="quit" onClick={() => {
          leaveGame();
          window.clearData();
          navigate("/")
        }}>Quit</div>
      </div>

      <NobleInfoHeader />
      <BasicLayout>
        {/*<pre>
          game mode: {voteStore.mode}
          <br />
          buildings: {buildingStore.buildingCount} <br />
          navStore: {navStore.path} <br />
          path: {location.pathname} <br />
          isVotingOpen: {voteStore.isVoteOpen.toString()}
        </pre>
        */}

        <Routes>
          <Route path="/" element={<NobleInfo />} />
          <Route path="/rebellion" element={<RebellionInput />} />
          {voteStore.mode === "vote" && (
            <Route path="/vote" element={<VoteInput />} />
          )}
          {voteStore.mode === "successor" && (
            <Route path="/successor" element={<SuccessorInput />} />
          )}
          <Route path="/scheme" element={<SchemeInput />} />
          <Route path="/building" element={<BuildingsInput />} />
          <Route path="/scheme-info" element={<SchemeInfo />} />
          <Route path="/pause" element={<Pause />} />
          <Route path="/*" element={<Navigate to="/game" replace />} />
        </Routes>
      </BasicLayout>
      {
        authStore.decodedLoginToken?.grant_type === "STEAM_RETAIL" ? <div /> : <WishlistButton />
      }
    </>
  );
});
