import React from 'react';
import TopNav from "components/topnav";
import Background from "components/background";
import NameInputField, { isValidName } from "components/nameInput";
import PronounChoiceButton from "components/pronounChoiceButton";
import Container from "components/container";
import FlagButton from "components/flagButton";
import { useNavigate } from "react-router-dom";
import accountStore from "stores/accountStore";
import authStore from "stores/authStore";
import { anonymousLogin } from "actions/login";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import {clearAllData} from "../../actions/clearData";

export default observer(function Login(props) {
  const navigate = useNavigate();
  const isValid = () => {
      return (
          isValidName(accountStore.name) &&
          accountStore.pronouns &&
          accountStore.pronouns.length > 1
      );
  };

  return (
      <>
      <TopNav />
      <Background />
      <Container className={styles.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if(authStore.decodedLoginToken?.display_name && !accountStore.name){
                accountStore.name = authStore.decodedLoginToken.display_name;
            }

            if (!accountStore.name || accountStore.name.length < 1) {
              return;
            }

            // Anonymous-granted tokens are not a security risk and can just be regenerated, but if we're somehow here with an expired Steam token, we force re-auth.
            if(!authStore.loginToken){
                console.log("No stored anonymous token found. Performing login.");
                anonymousLogin()
                  .then(() => {
                    navigate("/join");
                  })
            } else if (authStore.isSteamLogin() && authStore.isTokenExpired()) {
                // I *think* this should never happen? The home-page forces re-auth of Steam tickets.
                console.log("Steam-authenticated login token expired. Forcing fresh login.");
                clearAllData();
                authStore.clear();
                navigate("/");
            } else {
              navigate("/join");
            }
          }}
        >
          <NameInputField />
          <div className={styles.inputGroup}>
            <h2>Pronouns</h2>
              <PronounChoiceButton pronounKey={"they"} displayString={"They/Them"} enabled={accountStore.pronouns === "they"}/>
              <PronounChoiceButton pronounKey={"he"} displayString={"He/Him"} enabled={accountStore.pronouns === "he"}/>
              <PronounChoiceButton pronounKey={"she"} displayString={"She/Her"} enabled={accountStore.pronouns === "she"}/>
          </div>
          <br />

          <FlagButton
            type="submit"
            disabled={!isValid()}
            className={styles.flagButton}
          >
            {" "}
            Next
          </FlagButton>
        </form>
      </Container>
    </>
  );
});
