import matchStore from "stores/matchStore";
import accountStore from "stores/accountStore";
import authStore from "stores/authStore";
import wsStore from "stores/wsStore";
import navStore from "stores/navStore";
import { Client, Message } from "@stomp/stompjs";
import {requestSync, sendJoinRequest, sendLeaveRequest} from "actions/voting/sendVote"
import routeWs from "actions/routeWS";
import {clearAllData} from "../clearData";

const REACT_APP_WS_SCHEME = process.env.REACT_APP_WS_SCHEME;
const REACT_APP_WS_HOST = process.env.REACT_APP_WS_HOST;
const REACT_APP_WS_PORT = process.env.REACT_APP_WS_PORT;

const MAX_RECONNECT_ATTEMPTS = 20;

var closeSocketTimeout = null;

export function joinGame() {
  if(closeSocketTimeout != null){
    clearTimeout(closeSocketTimeout);
    closeSocketTimeout = null;
  }

  let displayName = accountStore.name;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authStore.loginToken}`,
    },
    body: JSON.stringify({ 'display_name': displayName }),
  };
  return fetch(
    `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/game/join/${accountStore.gameJoinCode}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {      
      if(data.error) return data;
      authStore.gameToken = data.session_token;
      accountStore.gameId = data.game_id;

      setupClient();
      return data;
    });
}

export function leaveGame(){
  if(!wsStore.client || !wsStore.client.connected){
    return;
  }
  wsStore.gameMsgSubscription.unsubscribe();
  sendLeaveRequest();

  // Bit of weirdness here - if we shut down the client immediately, there exists some race condition,
  // where the websocket message gets dropped somewhere and never reaches the Unity client ** Even if it has reached the server before the connection is terminated **  
  // I'm really not sure what's going on there, so we slam in a few seconds delay to hope the client has recieved the !leave before we kill the socket
  // Note that we shouldn't receive any new messages during this time, because we've unsubscribed to the game event queue.
  // We keep a reference to the timeout so that we can cancel it if the user tries to reconnect before it expires.
  closeSocketTimeout = setTimeout(() => {
    wsStore.client.deactivate();
  }, 5000);
}

export function setupClient() {
  var gameId = accountStore.gameId;

  var client = new Client({
    // We have to pass the auth token as a query param here, rather than a HTTP header, because StompJS does not support passing custom headers to the HTTP handshake.
    // See: https://stackoverflow.com/questions/25486889/websocket-stomp-over-sockjs-http-custom-headers
    brokerURL: `${REACT_APP_WS_SCHEME}://${REACT_APP_WS_HOST}:${REACT_APP_WS_PORT}/ws?auth=${authStore.gameToken}`,
    connectHeaders: {
      host: "/",
    },
    debug: function (str) {
       //console.log(str);
    },
    connectionTimeout: 15000,
    reconnectDelay: 3000,
    heartbeatIncoming: 5000,
    heartbeatOutgoing: 5000,
  });
  var endpoint = `/exchange/amq.topic/state.${gameId}`;

  client.onConnect = function () {
    console.log(`[WS] - Successfully established websocket connection.`);
    let callback = function (arg) {
      var body = JSON.parse(arg.body);

      // The first message we receive is a success/error response to our subscription message.
      // Note that, for ~ reasons ~ an error response here may still have (successfully) established a connection to the endpoint. This is just the server telling us that it *shouldn't* have, even if it has.
      // 
      // So, if that's the case - terminate it and bounce to the base page.
      if(body.context === `SUBSCRIBE:${endpoint}`){
        if(body.status === "OK"){
          console.log(`[WS] Successfully subscribed to noble endpoint ${endpoint}.`);

          if(accountStore.isFirstConnect){
            console.log(`[WS] Successfully connected for the first time. Issuing !join and !sync`);
            sendJoinRequest(accountStore.pronouns);
            accountStore.isFirstConnect = false;
          } else {
            console.log(`[WS] Successfully reconnected after interruption. Issuing !join and !sync.`);
            sendJoinRequest(accountStore.pronouns);
            requestSync();
          }
          
          matchStore.numConnectionErrors = 0;
          matchStore.error = false;
        }
        else if(body.status === "ERROR"){
            console.log(`[WS] [ERROR] Received error when subscribing to endpoint ${endpoint}. Error was: '${body.error}'. Closing any existing connections.`);
            accountStore.joinGameError = body.error;
            matchStore.numConnectionErrors++;
            matchStore.error = true;
            matchStore.errorMsg = body.error;
        }
      }

      // This is the usual handler for game-state messages
      else {
        routeWs(body);
      }
    };

    console.log(`[WS] - SUBSCRIBING to endpoint ${endpoint}`);
    wsStore.gameMsgSubscription = client.subscribe(endpoint, callback, {
      authToken: authStore.gameToken,
    });
  };
  client.onChangeState = (info)=> {
    console.log(`[WS] - onChangeState fired. with info ${info}`);
  };
  client.onStompError = function (frame) {
    // Will be invoked in case of error encountered at Broker
    // Bad login/passcode typically will cause an error
    // Complaint brokers will set `message` header with a brief message. Body may contain details.
    // Compliant brokers will terminate the connection after any error
    console.log(`[WS] - Broker reported error: ${frame.headers["message"]} with body: ${frame.body}`);
  };
  client.onDisconnect = function () {
    console.log(`[WS] - onDisconnect fired.`);
  };
  client.onWebSocketError = function (err) {
    console.log(`[WS] - Websocket Error - Reconnecting...`);
    matchStore.numConnectionErrors++;

    // We only splash the error if we've tried - and failed - to reconnect once already.
    // Otherwise it would display every time someone unlocks their phone.
    if(matchStore.numConnectionErrors > 1) {
      matchStore.error = true;
      matchStore.errorMsg = `Attempting to reconnect… ${matchStore.numConnectionErrors}/${MAX_RECONNECT_ATTEMPTS}`;
      matchStore.errorTitle = "Lost Connection";
    }
    
    if(matchStore.numConnectionErrors >= MAX_RECONNECT_ATTEMPTS){
        console.log(`[WS] - Could not reconnect after ${MAX_RECONNECT_ATTEMPTS} attempts. Quitting.`);
        client.deactivate();
        clearAllData();
        navStore.navigate("/");
    }
  };

  client.activate();
  wsStore.client = client;
  return client;
}
