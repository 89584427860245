import { clearAllData }  from "actions/clearData";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { leaveGame } from "actions/join";
import { regionLabelsTextLower, regionNames } from "constants";
import { sendJoinTeamRequest } from "actions/voting/sendVote";
import kingdomStore from "stores/kingdomStore";
import authStore from "stores/authStore";
import rebellionStore from "stores/rebellionStore";
import regionStore from "stores/regionStore";
import schemeStore from "stores/schemeStore";
import voteStore from "stores/voteStore";
import matchStore from "stores/matchStore";
import accountStore from "stores/accountStore";
import styles from "./style.module.css"
import ChooseRegionButton from "../../components/switchRegionButton";
//no need for named function really cause default export.
//it'll just pick up the folder name cause index.jsx
//also no need for observable, cause the parent component already has it

// i named it because of the annoying warning
export default observer(function NobleInfo() {
  var title = ""
  var subtitle = ""  
  var isGameOver = false;
  const navigate = useNavigate();
  var regionList = [];
  
  if( kingdomStore.kingdom.is_early_gameover){
    isGameOver = true;
  }
  for (let regionName of Object.keys(regionStore.regions)) {
    regionList.push(regionName);
    if (regionStore.regions[regionName]?.has_won) {
      isGameOver = true;
    }
  }
  regionList.sort();
  if(isGameOver){
    title = "The reign is over";
    subtitle = "Play another game with the Monarch's successor to continue the story."
  }

  if(!title){
    if (rebellionStore.rebellions.some(x => (x.is_gathering || x.is_ongoing))) {
      title = "The Kingdom hangs on a knife's edge.";
      subtitle = <>Tap <span className={styles.rebellion}>REBELLIONS</span> above for more information about the Rebellion.</>
    } else if (schemeStore.currentScheme) {
      title = `Your fellow ${regionNames[accountStore.region]} are plotting`;
      
      subtitle = <>Tap <span className={`${styles.scheme} ${accountStore.region}-color `}>
      {schemeStore.currentScheme.toUpperCase()}
      </span> above for more information about your Region's Scheme.</>
    } else if (voteStore.isVoteAlmostOpen) {
      title = "Welcome to the Council.";
      subtitle = "Your monarch is deliberating. Return here when it's time to cast your vote.";
    } else {
      title = "Salutations, Noble.";
      subtitle = "Waiting for something exciting to happen...";
    }
  }

  return (
      <div className={styles.wrapper}>

        {(!isGameOver && (isNaN(matchStore.turn) || matchStore.turn === null)) &&
        <div>
          <div className={styles.header} data-textbox="main-title">
            <i> {title} </i>
          </div>

          <div className={styles.waiting} data-textbox="main-subtitle">
            <i> Welcome to the {regionNames[accountStore.region]}.</i>
          </div>

          <div className={styles.waiting} style={{marginTop: "5vh"}} data-textbox="main-subtitle">
            <i>At the start of a new Dynasty, premium players may select a new region below.</i>
          </div>

          <div className={styles.buttonWrapCenter}>
            {regionList.map(d => (<ChooseRegionButton region={d}/>))}
          </div>
        </div>
        }

        {(isGameOver || !(isNaN(matchStore.turn) || matchStore.turn === null)) &&
        <div>
          <div className={styles.header} data-textbox="main-title">
            {title}
          </div>
          <div className={styles.waiting} data-textbox="main-subtitle">
            <i>{subtitle}</i>
          </div>
        </div>
        }

        {isGameOver && <div className={styles.buttonWrap}><button className={styles.button} onClick={()=>{
          leaveGame();
          clearAllData();
          navigate("/")}}>Exit Game</button></div>}

        
      </div>
  );
});
