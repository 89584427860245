export default {
  messages: [
    {
      buildings: [
        {
          id: "0-barons-cathedral",
          region_id: "barons",
          build_state: "PREVIEW",
          command_char: "A",
          definition: {
            id: "cathedral",
            reward: { value: 1, stat_id: "faith" },
          },
        },
        {
          id: "0-barons-fortress",
          region_id: "barons",
          build_state: "PREVIEW",
          command_char: "B",
          definition: {
            id: "fortress",
            reward: { value: 1, stat_id: "military" },
          },
        },
        {
          id: "0-chiefs-aqueduct",
          region_id: "chiefs",
          build_state: "PREVIEW",
          command_char: "C",
          definition: {
            id: "aqueduct",
            reward: { value: 1, stat_id: "farming" },
          },
        },
        {
          id: "0-chiefs-library",
          region_id: "chiefs",
          build_state: "PREVIEW",
          command_char: "D",
          definition: {
            id: "library",
            reward: { value: 1, stat_id: "stability" },
          },
        },
        {
          id: "0-counts-aqueduct",
          region_id: "counts",
          build_state: "PREVIEW",
          command_char: "E",
          definition: {
            id: "aqueduct",
            reward: { value: 1, stat_id: "farming" },
          },
        },
        {
          id: "0-counts-library",
          region_id: "counts",
          build_state: "PREVIEW",
          command_char: "F",
          definition: {
            id: "library",
            reward: { value: 1, stat_id: "stability" },
          },
        },
      ],
      message_type: "BUILDING_STATE",
    },
    {
      kingdom: {
        id: "Kingdom",
        is_reign_over: false,
        stats: [
          { id: "authority", value: 5 },
          { id: "stability", value: 5 },
          { id: "treasury", value: 3000 },
          { id: "rebellionVictory", value: 0 },
          { id: "rebellionDefeat", value: 0 },
        ],
      },
      message_type: "KINGDOM_STATE",
    },
    {
      nobles: [
        {
          id: "52e48b6f-9a13-4565-b2d2-3b63e583de11",
          name: "Clay",
          full_name: "Baron Clay",
          region_id: "barons",
          wealth: 1000,
          shield: {
            noble_id: "52e48b6f-9a13-4565-b2d2-3b63e583de11",
            shield_definition_id: "shield05",
            colors: ["B9172FFF", "6F0B0DFF"],
            icon_id: "ShieldIcon01",
          },
        },
        {
          id: "bace057e-b4f3-45d5-873b-053f3f5d9497",
          name: "ywmr",
          full_name: "Chief ywmr",
          region_id: "chiefs",
          wealth: 1000,
          shield: {
            noble_id: "bace057e-b4f3-45d5-873b-053f3f5d9497",
            shield_definition_id: "shield08",
            colors: ["1B8BCBFF", "083248FF"],
            icon_id: "ShieldIcon04",
          },
        },
        {
          id: "3d96c0ba-8c91-4bc5-8584-52992d8e5def",
          name: "Test",
          full_name: "Countess Test",
          region_id: "counts",
          wealth: 1000,
          shield: {
            noble_id: "3d96c0ba-8c91-4bc5-8584-52992d8e5def",
            shield_definition_id: "shield06",
            colors: ["653E97FF", "382171FF"],
            icon_id: "ShieldIcon12",
          },
        },
      ],
      message_type: "NOBLE_STATE",
    },
    { rebellions: [], message_type: "REBELLION_STATE" },
    {
      regions: [
        {
          id: "barons",
          name: "Barons",
          territory: "the March",
          stats: [
            { id: "trade", value: 5 },
            { id: "military", value: 6 },
            { id: "farming", value: 5 },
            { id: "faith", value: 6 },
            { id: "defiance", value: 1 },
          ],
          scheme_data: null,
          current_scheme_step: null,
          has_won: false,
          scheme_goal_text: "",
        },
        {
          id: "chiefs",
          name: "Chiefs",
          territory: "the North",
          stats: [
            { id: "trade", value: 3 },
            { id: "military", value: 7 },
            { id: "farming", value: 3 },
            { id: "faith", value: 0 },
            { id: "defiance", value: 1 },
          ],
          scheme_data: null,
          current_scheme_step: null,
          has_won: false,
          scheme_goal_text: "",
        },
        {
          id: "counts",
          name: "Counts",
          territory: "the East",
          stats: [
            { id: "trade", value: 4 },
            { id: "military", value: 5 },
            { id: "farming", value: 7 },
            { id: "faith", value: 4 },
            { id: "defiance", value: 1 },
          ],
          scheme_data: null,
          current_scheme_step: null,
          has_won: false,
          scheme_goal_text: "",
        },
      ],
      message_type: "REGION_STATE",
    },
    {
      turn: { turn_number: 0, year: 948, season: 0 },
      message_type: "TURN_STATE",
    },
  ],
  message_type: "FULL_STATE",
};
