

export function getAsset(filename, defaultFilename) {
  var asset

  try {
    return require(`/src/assets/${filename}`);
  } catch (e){}

  try {
    asset  = require(`/src/assets/${defaultFilename}`);
  } catch (e){
    console.error(`cannot find ${filename} or ${defaultFilename} ${e}`)
    asset = ""
  }

  return asset;
}