
import styles from "./style.module.scss"
import voteStore from 'stores/voteStore';
import { getAsset } from "util/getAsset";

export default function SuccessorInput() {
    return (
      <div>
        <div className={styles.header}>Vote for a successor!</div>
        <div className={styles.information}>The monarch has been overthrown!</div>
        <div className={styles.information}>The council must now elect a new Monarch from the two most powerful regions.</div>
        
        <hr className={styles.separator}></hr>
        
        {
          voteStore.choices.map((choice, i) => {
            return <div className={styles.itemBlock}>
              <img
                className={styles.itemEmblem}
                src={getAsset(`banner_${choice.region_id}.png`, "banner_counts.png")} 
                alt={`banner_${choice.region_id}`}
              />
              <div className={`${styles.itemHeader} ${choice.region_id}-color`}>
                <div className={styles.pointyRightHeader}></div>
                {choice.region_id.toUpperCase()} OF THE ...
              </div>
              <div className={`${styles.itemValue} ${choice.region_id}-accent-bg`}>
                <div className={`${styles.pointyRightValue} ${choice.region_id}-accent-bg`}></div>
                {choice.text}
              </div>
            </div>
          })
        }

      </div>
    );
}