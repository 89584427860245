import Button from "../basicButton";
import styles from "./style.module.scss";
import globalStyles from "../../globalStyle.scss"
import accountStore from "../../stores/accountStore";
import cx from "classnames";
import authStore from "../../stores/authStore";
import {sendJoinTeamRequest} from "../../actions/voting/sendVote";
import { regionLabelsTextLower } from "constants";

export default function ChooseRegionButton(props) {
    var region = props.region;
    var enabled = authStore.decodedLoginToken?.grant_type === "STEAM_RETAIL" && region !== accountStore.region && !accountStore.pendingJoinTeamRequest && (region != accountStore.region);
    var text = region == accountStore.region ? `You're in the ${regionLabelsTextLower[region]}` : `Join the ${regionLabelsTextLower[region]}`

    var className = cx(styles.switchRegion)
    if(enabled)
        className += ` ${region}-bg`

    return(
        <Button
            type="button"
            className={className}
            disabled={!enabled}
            onClick={() => {
                accountStore.pendingJoinTeamRequest = true;
                sendJoinTeamRequest(accountStore.pronouns, region);
            }}
        >
            {text}
        </Button>
    )
}