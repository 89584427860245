import matchStore from 'stores/matchStore';
import styles from './style.module.css';
import { observer } from "mobx-react";

export default observer(function ErrorModal(props) {
    return  <div className={styles.fillPage}>
        <div className={styles.box}>
            <div className={styles.xButton} onClick={() => matchStore.events.emit("ERROR_MODAL")}>X</div>
            <div className={styles.title}>{matchStore.errorTitle}</div>
            <div className={styles.message}>{matchStore.errorMsg}</div>
        </div>
    </div>
})