import styles from "./style.module.css";
import VoteItem from "components/voteItem";
import voteStore from "stores/voteStore";
import { sendVoteRequest } from "actions/voting/sendVote";
import { regionLabelsText, letters, regionNames } from "constants";
import accountStore from "stores/accountStore";
export default function SchemeInput() {
  return (
    <div>
      <div className={`${styles.header} ${voteStore.region}`}> {regionNames[accountStore.region]} Only Vote</div>
      <div className={styles.information}>
        Your region is voting on its secret Scheme to take over the crown.
      </div>
      {voteStore.choices.map((x, i) => {
        return (
          <VoteItem
            key={x.text + i}
            value={i}
            text={x.text}
            voteData={x}
            region={voteStore.region}
            disabled={x.locked}
            onClick={() => {
              if(x.locked){
                  return;
              }
              sendVoteRequest(letters[i]);
              voteStore.clear();
            }}
          />
        );
      })}
    </div>
  );
}
